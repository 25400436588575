const scrollThreshold = 90;

const isBeyondThreshold = () => {
    return (
        document.body.scrollTop > scrollThreshold ||
        document.documentElement.scrollTop > scrollThreshold
    );
};

window.onscroll = () => {
    if (isBeyondThreshold()) {
        document.getElementById("page-header").classList.add("header-fixed");
    } else {
        document.getElementById("page-header").classList.remove("header-fixed");
    }
};
