const isInViewPort = (element) => {
    // Get the bounding client rectangle position in the viewport
    const bounding = element.getBoundingClientRect();

    // Checking part. Here the code checks if it's *fully* visible
    // Edit this part if you just want a partial visibility
    if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
    ) {
        return true;
    } else {
        return false;
    }
};

/* DAI ALL'ELEMENTO HTML LA CLASSE .animate-on-scroll 
e il data-animate usando il nome dell'animazione presente nel sito https://animate.style/  */
var elements = document.getElementsByClassName("animate-on-scroll");
window.addEventListener(
    "scroll",
    function (event) {
        for (var element of elements) {
            if (isInViewPort(element)) {
                if (!element.classList.contains("animate__animated")) {
                    var animationName = element.getAttribute("data-animate");
                    element.classList.add("animate__animated");
                    element.classList.add(`${animationName}`);
                    element.style.display = "block";
                }
            }
        }
    },
    false
);
