import Cookies from 'js-cookie'
const cookie = Cookies.get("medeia");
const cookieElement = document.getElementById("pop-up-privacy-policy");

const hideCookieSection = () => {
    cookieElement.style.display = "none";
};

if (cookie) {
    hideCookieSection();
}

document.getElementById("accept-cookie").addEventListener("click", () => {
    Cookies.set("medeia", "true", { expires: 30 });
    hideCookieSection();
});

document.getElementById("close-cookie").addEventListener("click", () => {
    hideCookieSection();
});
